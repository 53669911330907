import React from "react"
import speakerdefault from "../../../images/speaker-default.jpg"
import duration from "../utils/duration"
const AgendaSpeaker = props => {
  const { speaker_job_title: job_title = "" } = props.value
  const CalculatedDuration = duration(props.value.duration)
  return (
    <div className="d-xs-flex">
      <div className="mr-25 mr-xs-20">
        <img
          className="radius-circle"
          src={
            props.value.speaker_image_path !== ""
              ? props.value.speaker_image_path
              : speakerdefault
          }
          alt={`Profile of ${props.value.speaker_name}`}
          height="50px"
          width="50px"
        />
      </div>
      <div>
        {!!props.value.speaker_name && <div>{props.value.speaker_name}</div>}
        {!job_title || <div>
          {job_title}
        </div>
        }
        {!!props.value.duration && (
          <div>
            {CalculatedDuration.Hours !== 0
              ? CalculatedDuration.Hours +
              (CalculatedDuration.Hours > 1 ? " Hours " : " Hour ")
              : ""}
            {CalculatedDuration.Minutes !== 0
              ? CalculatedDuration.Minutes +
              (CalculatedDuration.Minutes > 1 ? " Minutes" : " Minute")
              : ""}
          </div>
        )}


      </div>
    </div>
  )
}

export default AgendaSpeaker
