import React from "react"
import Event from "./components/event"
import Tabs from "react-responsive-tabs"

const Agenda = props => {

  function getTabs(data, presentations, max = 0, showBtn = false) {
    return data
      .map((value, dindex) => {
        return {
          date: value.display_title,
          key: dindex,
          details: value.presentations.map((value, index) => {
            if (!max || index <= max - 1) {
              let findByDrupaWebcastId = presentations.find(presentationValue => {
                return presentationValue.drupalWebcastId === value.presentation_id
              })
              if (findByDrupaWebcastId) {
                if (findByDrupaWebcastId.status === "live") {
                  value.is_live = true
                } else if (findByDrupaWebcastId.status === "ondemand") {
                  value.is_past = true
                }
              }
              return <Event key={index} value={value} showBtn={showBtn} tabIndex={dindex}/>
            } else{
              return ''
            }
          }),
        }
      })
      .map((event, index) => ({
        title: event.date,
        getContent: () => event.details,
        key: index,
        tabClassName: "tab",
        panelClassName: "panel",
      }))
  }

  return (
    <Tabs
      items={getTabs(props.days, props.presentations, props.max, props.showBtn)}
      selectedTabKey={props.startIndex}
      transform={props.transform}
      transformWidth={414}
    />
  )
}

export default React.memo(Agenda)
