const duration = mins => {
  const hoursDifference = Math.floor(mins / 60)
  mins -= hoursDifference * 60

  return {
    Hours: hoursDifference,
    Minutes: mins,
  }
}

export default duration
